var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('GoBackHeader',{attrs:{"title":_vm.$t('institute.syllabi-comparison.select-degree')}}),_c('v-form',{staticClass:"base-form mt-8",on:{"submit":function($event){$event.preventDefault();return _vm.submit_form.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('SelectDegree',{attrs:{"title":_vm.$t('institute.syllabi-comparison.your-degree'),"institute_type":_vm.InstituteType.NATIONAL},on:{"selected_institute":_vm.update_national_selected_institute,"selected_program":_vm.update_national_selected_program,"selected_degree_file":_vm.update_national_selected_degree_file,"selected_degree_content":_vm.update_national_selected_degree_content}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('SelectDegree',{attrs:{"title":_vm.$t('institute.syllabi-comparison.global-degree'),"institute_type":_vm.InstituteType.GLOBAL},on:{"selected_institute":_vm.update_global_selected_institute,"selected_program":_vm.update_global_selected_program,"selected_degree_file":_vm.update_global_selected_degree_file,"selected_degree_content":_vm.update_global_selected_degree_content}})],1)],1),_c('v-row',[_c('v-col',[(
            _vm.national_institute_data.national_institute &&
            (_vm.national_institute_data.national_institute_program ||
              _vm.national_institute_data.selected_national_degree_file ||
              _vm.national_institute_data.selected_national_degree_content) &&
            _vm.global_institute_data.global_institute &&
            (_vm.global_institute_data.global_institute_program ||
              _vm.global_institute_data.selected_global_degree_file ||
              _vm.global_institute_data.selected_global_degree_content)
          )?_c('v-btn',{staticClass:"base-submit-button",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("institute.syllabi-comparison.start-analyzing"))+" ")]):_c('v-btn',{staticClass:"base-disable-button",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("institute.syllabi-comparison.start-analyzing"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }